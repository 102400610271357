.section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    color: white;
    text-align: center;
}
.hero {
    background-image: url(../img/heroImage.jpg);
    background-size: cover;
    justify-content: center;
    object-fit: fill;
}
.resources {
    background-image: url(../img/oldPaper.jpg);
    background-size: cover;
    object-fit: fill;
    justify-content: space-around;
    padding: 10%;
}
.resourcesCards {
    display: flex;
    gap: 5em;
    flex-wrap: wrap;
    justify-content: center;
    margin: 50px 0;
}
.podcasts {
    background-image: url(../img/podcastBg.jpg);
    background-size: cover;
    object-fit: fill;
    justify-content: space-around;
}
.podcastsCard {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 2em;
}
.podcastDescription {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 3em;
    max-width: 40vw;
}
.articles{
    background-image: url(../img/notebook.jpg);
    background-size: cover;
    object-fit: fill;
    justify-content: space-around;
}

@media screen and (max-width: 800px) {
    .section {
        padding: 50px 0;
    }
    .resources {
        height: 100%;
    }
    .podcasts {
        height: 100%;
    }
    .articles {
        height: 100%;
    }
    .podcastsCard {
        flex-direction: column;
    }
    .podcastDescription {
        max-width: 90vw;
    }
    
}