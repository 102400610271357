.podcastPg {
      background-image: url(../img/PodcastImg.jpg);
}
.spotifyCard {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1.5em;
      padding: 0 20px;
}
@media screen and (max-width: 800px) {
      .spotifyCard {
            flex-direction: column;
            align-items: center;
      }
      .podcastIframe {
            width: 100%;
          }
      
}