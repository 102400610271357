.header {
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    color: white;
    background-size: cover;
    object-fit: fill;
    justify-content: space-around;
    margin-bottom: 20px;
}
.header h5 {
    text-align: center;
}
.resourcesPg{
    background-image: url(../img/resourcesPgBg.jpg);
    padding: 0 10px;
}
@media (max-width:800px) {
    .header h1{
        font-size: 3rem;
    }
}
@media (max-height:1000px) {
    .header{
        height: 100%;
        padding: 40px 0;
    }
}